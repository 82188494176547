<script>
import {mapActions, mapGetters} from "vuex";
import CartTable from "@/components/CartTable.vue";

export default {
  name: "CartModal",
  components: {CartTable},
  computed: {
    ...mapGetters('cart', [
        'products'
    ]),
    show: {
      get(){
        return this.$store.state.cart.showCartModal
      },
      set(value){
        this.setShowCartModal(value)
      }
    }
  },
  methods:{
    ...mapActions('cart', [
        'setShowCartModal',
        'clearProductsArray'
    ]),
    close(){
      this.setShowCartModal(false)
    }
  }
}
</script>

<template>
  <Modal wrapperClass="modal-cart" v-model="show" title="Корзина" :modal-class="'modal modal-xl rounded-xl'">
    <div class="">
      <cart-table></cart-table>
      <div class="flex justify-between p-4">
        <div>
          <button v-if="products" class="py-1.5 px-3 rounded bg-[#d9534f] text-white hover:bg-[#c9302c]" @click="clearProductsArray(); close()">Очистить корзину</button>
        </div>
        <div class="flex gap-2">
          <button class="py-1.5 px-3 rounded bg-[#337ab7] text-white hover:bg-[#286090]" @click="close">Продолжить покупки</button>
          <button v-if="products" class="py-1.5 px-3 rounded bg-[#5cb85c] text-white hover:bg-[#449d44]" @click="$router.push('/cart'); close()">Оформить заказ</button>
        </div>
      </div>
    </div>
  </Modal>
</template>
<style lang="scss">
  .modal-cart{
    .vm-content{
      padding: 0;
    }

    .vm-titlebar {
      background: url("@/assets/header_bg.jpg");
      color: white;
      border-radius: 10px 10px 0 0;
      padding: 5px 15px;
    }
  }
</style>