<template>
    <div class="product-wrapper">
      <router-link :to="{name: 'Product', params: { category_alias: 'clothes', product_alias: product.alias}}">
        <img class="inline" :src="picture" alt="">
        <hr>
        <div class="product-color-wrapper">
          <color-carousel-grid @chooseColor="setColor" :count-slide="4" :colors = product.productColors :product-id = product.id></color-carousel-grid>
        </div>
        <p v-html="product.name"></p>
        <div>
          <del><span class="red-price">{{product.price_for_people}}</span></del>
          <span class="orange-price">{{product.price_for_people}}</span>
        </div>
        <div @click.prevent v-if="product.variations" class="product__bottom">
          <p @click.prevent="toggleSizeGrid" class="product__bottom-switcher">
            <template v-if="size">{{size.name}}</template>
            <template v-else>размер</template>
            <font-awesome-icon v-if="sizeGridVisible" :icon="['fas', 'angle-up']" />
            <font-awesome-icon v-else :icon="['fas', 'angle-down']" />
          </p>
          <div class="product__bottom-counter">
            <span @click="decrementCount">-</span>
            <p>{{count}}</p>
            <span @click="incrementCount">+</span>
          </div>
          <div class="product__bottom-cart">
            <font-awesome-icon @click="addToCart" :icon="['fas', 'cart-shopping']" />
          </div>
        </div>
        <div v-else class="product__bottom">Нет в продаже</div>
        <transition name="fade">
          <div v-show="sizeGridVisible">
            <size-grid v-if="colorId" @chooseSize="setSize" :color-id="colorId" :number-of-sizes="product.variations" :color-image="imageCurrentColor" :dimensionalGridId="product.dimensional_grid_id" :size-index=sizeIndex></size-grid>
          </div>
        </transition>
      </router-link>
    </div>
</template>

<script>
import ColorCarouselGrid from "@/components/ColorCarouselGrid";
import SizeGrid from "@/components/SizeGrid";
import Store from "@/store";
import {useToast} from 'vue-toastification'
import {mapActions, mapGetters} from "vuex";
const toast = useToast()

export default {
  name: 'ProductCardGrid',
  data(){
    return {
      sizeGridVisible: false,
      sizeId: undefined,
      sizeIndex: undefined,
      colorId: undefined,
      count: 0
    };
  },
  components: {
    ColorCarouselGrid,
    SizeGrid
  },
  props: {
    product: {
      type: Object,
      required: true,
      default: undefined
    },
  },
  computed: {
    ...mapGetters('app', [
      'dimensionalGrids'
    ]),
    productColor(){
      if(this.colorId !== undefined){
        return this.product.productColors.find(item => item.color_id === this.colorId)
      }

      return this.product.productColors.find(item => item.is_default === true)
    },
    imageCurrentColor(){
      return this.productColor ? this.productColor.pictureColor : null
    },
    price(){
      if(this.product === undefined){
        return 0
      }
      return this.product.price
    },
    sum(){
      if(this.price === 0){
        return 0;
      }
      return new Intl.NumberFormat().format(this.price * this.count) + ' ₽'
    },
    size(){
      return this.dimensionalGrids[this.product.dimensional_grid_id].sizes[this.sizeIndex];
    },
    picture(){
      return this.productColor.gridPicture
    },
    maxCount(){
      if(this.colorId === undefined || this.sizeId === undefined){
        return 0
      }
      let index = this.colorId.toString() + '_' + this.sizeId.toString()
      return this.product.variations[index].count
    }
  },
  methods: {
    ...mapActions('cart', [
      'setShowCartModal'
    ]),
    toggleSizeGrid(){
      this.sizeGridVisible = !this.sizeGridVisible;
    },
    setSize(data) {
      this.sizeId = data.id
      this.sizeIndex = data.index
      this.setCount()
      this.sizeGridVisible = false
    },
    setCount(){
      if(this.sizeIndex === undefined){
        this.count = 0
        return
      }
      this.count = 1
    },
    setColor(colorId){
      this.colorId = Number(colorId)
    },
    incrementCount(){
      if(this.colorId === undefined || this.sizeId === undefined){
        return
      }
      if(this.count < this.maxCount){
        this.count++
      }
    },
    decrementCount(){
      if(this.count > 1){
        this.count--
      }
    },
    addToCart(){
      if(this.sizeId === null){
        toast.warning("Выберите размер!")
        return
      }
      if(this.colorId === null){
        toast.warning("Выберите цвет!")
        return
      }
      let product = {
        id: this.product.id,
        productColor: this.productColor,
        name: this.product.name,
        size: this.size,
        price: this.price,
        count: this.count,
        maxCount: this.maxCount,
        sum: this.sum,
        weight: this.product.weight,
        alias: this.product.alias
      }
      Store.dispatch('cart/addProduct', product)
      this.setShowCartModal(true)
    }
  },
  mounted() {
    this.colorId = this.product.productColors.filter(item => item.is_default)[0].color_id
  }
}
</script>

<style scoped lang="scss">
  .product-wrapper{
    margin: 3px 0;
    width: 100%;
    background-color: #fff;
    text-align: center;

    .fade-enter-active{
      transition: opacity 0.5s;
    }

    .fade-leave-active {
      transition: opacity 0.2s;
    }
    .fade-enter-from,
    .fade-leave-to {
      opacity: 0;
    }

    .product-color-wrapper{
      width: 100%;
    }

    &:hover{
      box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 1);
      cursor: pointer;
    }

    a{
      text-decoration: none;
      color: inherit;
    }

    img {
      max-width: 100%;
      height: 240px;
    }

    hr{
      width: 75%;
      margin: auto;
      color: #ddd;
      height: 2px;
    }

    .product__bottom{
      background-color: #656565;
      padding: 5px 10px;
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;

      >*{
        width: 30%;
      }

      .product__bottom-switcher{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        border-right: 1px solid white;
        padding-right: 10px;
      }

      .product__bottom-counter{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        border-right: 1px solid white;

        span{
          &:first-child{
            cursor: pointer;
          }
          &:last-child{
            position: relative;
            top: -2px;
            cursor: pointer;
          }
        }
      }

      .product__bottom-cart{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
</style>
<style lang="scss">
//.product-color-wrapper{
//  .carousel__prev{
//    left: -40px;
//  }
//
//  .carousel__next{
//    right: -40px;
//  }
//}
</style>
