import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProductView from '../views/ProductView.vue'
import CartView from '../views/CartView.vue'
import CategoryView from "@/views/CategoryView";
import UserView from "@/views/UserView.vue";
import AboutView from "@/views/ContactsView.vue";
import DiscountCategoryView from "@/views/DiscountCategoryView.vue";
import ContactsView from "@/views/ContactsView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/catalog/:category_alias/:product_alias',
    name: 'Product',
    component: ProductView
  },
  {
    path: '/catalog/:category_alias',
    component: CategoryView
  },
  {
    path: '/cart',
    name: 'cart',
    component: CartView
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: ContactsView
  },
  {
    path: "/user",
    name: 'user',
    component: UserView
  },
  {
    path: "/discount",
    name: 'discount',
    component: DiscountCategoryView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    window.scrollTo(0,0);
  }
})

export default router
