export const cartStore = {
    state: {
        productsArray: undefined,
        showCartModal: false,
        cdekPvz: undefined
    },
    getters: {
        totalSum(state) {
            if (state.productsArray === undefined) {
                return 'Пусто'
            }
            let sum = 0;
            state.productsArray.forEach(function (item){
                sum += item.price * item.count
            })

            return new Intl.NumberFormat().format(sum) + ' руб.'
        },
        totalCount(state) {
            if (state.productsArray === undefined) {
                return 0
            }
            return state.productsArray.reduce(function (previousValue, currentValue) {
                return Number(previousValue) + Number(currentValue.count)
            }, '')
        },
        totalWeight(state){
            if (state.productsArray === undefined) {
                return 0
            }
            return state.productsArray.reduce(function (previousValue, currentValue) {
                return Number(previousValue) + Number(currentValue.weight)
            }, '')
        },
        isEmpty(state) {
            return state.productsArray === undefined
        },
        products(state) {
            return state.productsArray
        }
    },
    mutations: {
        addProduct(state, product) {
            if(state.productsArray){
                let existsProduct = state.productsArray.find(item => item.id === product.id && item.productColor.id === product.productColor.id && item.size.id === product.size.id)
                if(existsProduct !== undefined){
                    if(existsProduct.count < existsProduct.maxCount){
                        existsProduct.count += product.count
                    }
                    return
                }
            }
            if(state.productsArray === undefined){
                state.productsArray = []
            }
            state.productsArray.push(product)
        },
        deleteProduct(state, index) {
            state.productsArray.splice(index, 1)
            if(state.productsArray.length === 0){
                state.productsArray = undefined
            }
        },
        removeProductById(state, { id, sizeId, colorId }) {
            const index = state.productsArray.findIndex(item =>
                item.id == id && item.size.id == sizeId && item.productColor.id == colorId
            );

            if (index !== -1) {
                state.productsArray.splice(index, 1);
                if (state.productsArray.length === 0) {
                    state.productsArray = undefined;
                }
            }
        },
        setShowCartModal(state, status){
            state.showCartModal = status
        },
        clearProductsArray(state){
            state.productsArray = undefined
        },
        setCdekPvz(state, cdekPvz){
            state.cdekPvz = cdekPvz
        },
    },
    actions: {
        addProduct(context, product) {
            context.commit('addProduct', product)
        },
        deleteProduct(context, index) {
            context.commit('deleteProduct', index)
        },
        incrementProduct(context, index){
            let product = context.state.productsArray[index]
            if(product.count < product.maxCount){
                context.state.productsArray[index].count++
            }
        },
        decrementProduct(context, index){
            let product = context.state.productsArray[index]
            if(product.count > 1){
                context.state.productsArray[index].count--
            }else{
                context.commit('deleteProduct', index)
            }
        },
        removeProductById(context, { id, sizeId, colorId }) {
            context.commit('removeProductById', { id, sizeId, colorId });
        },
        setShowCartModal(context, status){
            context.commit('setShowCartModal', status)
        },
        clearProductsArray(context){
            context.commit('clearProductsArray')
        },
        setCdekPvz(context, CdekPvz){
            context.commit('setCdekPvz', CdekPvz)
        },
    },
    namespaced: true
}